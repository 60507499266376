import { render, staticRenderFns } from "./TurnoInfo.vue?vue&type=template&id=32836834&scoped=true&"
import script from "./TurnoInfo.vue?vue&type=script&lang=js&"
export * from "./TurnoInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32836834",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle})
