<template>
<div>
    <v-card flat class="px-0 rounded-lg">
        <v-list-item class="px-0 my-1 ml-2" style="height:25px;min-height: 25px;">
            <v-list-item-avatar class="mr-1 rounded-lg" color="secondary" tile width="8" height="12" min-width="10" />
            <v-list-item-content class="py-0">
                <v-list-item-subtitle class="font-weight-medium mb-0">
                    <span>Inspectores de Linea en Turno</span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <Ils :fecha="fecha"/>
    </v-card>
</div>
</template>

<script>
export default {
    name: 'TurnoInfo',
    props: ['info', 'fecha'],
    components: {
        Ils: () => import('@/components/Ils')
    },
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
